import { render } from "./ServiceSetting.vue?vue&type=template&id=bd4c65b0"
import script from "./ServiceSetting.ts?vue&type=script&lang=ts"
export * from "./ServiceSetting.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "bd4c65b0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('bd4c65b0', script)) {
    api.reload('bd4c65b0', script)
  }
  
  module.hot.accept("./ServiceSetting.vue?vue&type=template&id=bd4c65b0", () => {
    api.rerender('bd4c65b0', render)
  })

}

script.__file = "src/views/AdminPanel/pages/ServiceSettings/components/ServiceSetting/ServiceSetting.vue"

export default script