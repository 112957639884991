import { render } from "./ServiceSettings.vue?vue&type=template&id=41705614"
import script from "./ServiceSettings.ts?vue&type=script&lang=ts"
export * from "./ServiceSettings.ts?vue&type=script&lang=ts"

import "./serviceSettings.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "41705614"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('41705614', script)) {
    api.reload('41705614', script)
  }
  
  module.hot.accept("./ServiceSettings.vue?vue&type=template&id=41705614", () => {
    api.rerender('41705614', render)
  })

}

script.__file = "src/views/AdminPanel/pages/ServiceSettings/ServiceSettings.vue"

export default script