import { render } from "./NumberInput.vue?vue&type=template&id=763e6a0c"
import script from "./NumberInput.ts?vue&type=script&lang=ts"
export * from "./NumberInput.ts?vue&type=script&lang=ts"

import "./numberInput.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "763e6a0c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('763e6a0c', script)) {
    api.reload('763e6a0c', script)
  }
  
  module.hot.accept("./NumberInput.vue?vue&type=template&id=763e6a0c", () => {
    api.rerender('763e6a0c', render)
  })

}

script.__file = "src/views/AdminPanel/pages/ServiceSettings/components/NumberInput/NumberInput.vue"

export default script